const getCookie = (name) => {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        // eslint-disable-next-line no-useless-escape
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
const setCookie = (name, value, options = {}) => {
  options = {
    path: "/",
    // при необходимости добавьте другие значения по умолчанию
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
};
// пример setCookie('user', 'John', {secure: true, 'max-age': 3600});
const deleteCookie = (name) => {
  setCookie(name, "", {
    "max-age": -1,
  });
};
let idGeneration = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaa'
  return s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4();
};
export { getCookie, setCookie, deleteCookie, idGeneration };
