import { useEffect } from "react";
import myAxios from "../../utility/myAxios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCookie,
  idGeneration,
  setCookie,
  deleteCookie,
} from "../../utility/cookie";

export default function Cookie() {
  const location = useLocation();
  const navigate = useNavigate();
  const guestLocation = ["/", "/Auth"];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let PartnerID = getCookie("PartnerID");
    const effect = async () => {
      if (PartnerID) {
        let res = await myAxios("IsPartner", "post", { PartnerID });
        if (res?.data) {
          if (guestLocation.includes(location.pathname)) {
            
            navigate("/Profile");
          }

          
        } else {
          deleteCookie("PartnerID");
          navigate("/Auth");
        }
      } else {
        let DeviceID = getCookie("DeviceID");
        if (DeviceID) {
          let res = await myAxios("GetPartnerByDeviceID", "post", { DeviceID });
          if (res) {
            setCookie("PartnerID", res.PartnerID, {
              maxAge: 365 * 24 * 60 * 60,
            });
            navigate("/Profle");
          } else {            
            if (!guestLocation.includes(location.pathname)) {
              navigate("/Auth");
            }
          }
        } else {
          let id = idGeneration();
          setCookie("DeviceID", id, { maxAge: 365 * 24 * 60 * 60 });
          navigate("/Auth");
        }
      }
    };
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
}
