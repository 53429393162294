import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import ErrorPage from "./error/error-page";

const AuthPage = React.lazy(() => import("./routes/AuthPage"));
const ProfilePage = React.lazy(() => import("./routes/ProfilePage"));
const RegistrationPage = React.lazy(() => import("./routes/RegistrationPage"));
const CatalogPage = React.lazy(() => import("./routes/CatalogPage"));
const BasketPage = React.lazy(() => import("./routes/BasketPage"));
const StockPage = React.lazy(() => import("./routes/StockPage"));

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <React.Suspense fallback={<h2>Loading...</h2>}>
        <AuthPage />
      </React.Suspense>
    ),
  },
  {
    path: "/Auth",
    errorElement: <ErrorPage />,
    element: (
      <React.Suspense fallback={<h2>Loading...</h2>}>
        <AuthPage />
      </React.Suspense>
    ),
  },
  {
    path: "/Profile",
    errorElement: <ErrorPage />,
    element: (
      <React.Suspense fallback={<h2>Loading...</h2>}>
        <ProfilePage />
      </React.Suspense>
    ),
  },
  {
    path: "/Registration",
    errorElement: <ErrorPage />,
    element: (
      <React.Suspense fallback={<h2>Loading...</h2>}>
        <RegistrationPage />
      </React.Suspense>
    ),
  },
  {
    path: "/Catalog",
    errorElement: <ErrorPage />,
    element: (
      <React.Suspense fallback={<h2>Loading...</h2>}>
        <CatalogPage />
      </React.Suspense>
    ),
  },
  {
    path: "/Basket",
    errorElement: <ErrorPage />,
    element: (
      <React.Suspense fallback={<h2>Loading...</h2>}>
        <BasketPage />
      </React.Suspense>
    ),
  },
  {
    path: "/Stock",
    errorElement: <ErrorPage />,
    element: (
      <React.Suspense fallback={<h2>Loading...</h2>}>
        <StockPage />
      </React.Suspense>
    ),
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(<RouterProvider router={router}/>);
