import axios from "axios";

const myAxios = async (url,method,data) => {
    try{
        
        let res = await axios({   
          method,
          url: `http://flower-trade.ru:85/BR/hs/br/rest_index/${url}`,
          data,
          headers: {Accept: 'application/json','Content-Type': "text/plain"}
        });
        return res
    }
    catch{
        console.log('error')
        return false
    }
};
export default myAxios